<template>
  <v-col
    sm="12"
    lg="4"
    :class="{
      'px-0': mobile
    }"
  >
    <v-row>
      <v-col
        cols="6"
        align-self="end"
      >
        <v-select
          color="teal"
          item-color="teal"
          :value="params.transitProcedureCondition"
          :items="conditions"
          hide-details="auto"
          background-color="white"
          class="ba-0 rounded"
          filled
          rounded
          label="Процедура"
          @change="(value) => setCondition('transitProcedureCondition', value)"
        />
      </v-col>
      <v-col
        v-if="$can($options.EPI_DEPARTMENT_FILTER)"
        cols="6"
        align-self="end"
      >
        <v-select
          :value="params.childDivisionId"
          :items="departments"
          item-text="divisionName"
          item-value="id"
          hide-details="auto"
          background-color="white"
          color="teal"
          item-color="teal"
          class="ba-0 rounded"
          filled
          rounded
          label="Отдел"
          @change="(value) => setCondition('childDivisionId', value)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import {mapGetters} from 'vuex'

const TYPE = 'epi'
const ALL_DEPARTMENTS = {id: null, divisionName: "ВСЕ ОТДЕЛЫ"}


export default {
  EPI_DEPARTMENT_FILTER: 'epi.department.filter',
  data: () => ({
    departments: [],
    params: {
      transitProcedureCondition: null,
      childDivisionId: null,
    },
    conditions: [
      {
        text: 'ВСЕ ТИПЫ',
        value: null
      },
      {
        text: 'ИМ',
        value: 'ИМ'
      },
      {
        text: 'ЭК',
        value: 'ЭК'
      },
      {
        text: 'ТР',
        value: 'ТР'
      },
      {
        text: 'ВТ',
        value: 'ВТ'
      },
      {
        text: 'ТС',
        value: 'ТС'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      typeDependentParams: 'journal/getTypeDependentParams'
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  watch: {
    typeDependentParams: {
      handler() {
        this.setValues()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.$can(this.$options.EPI_DEPARTMENT_FILTER)) {
      this.loadDepartments()
    } else {
      this.clearDepartment()
    }
  },
  methods: {
    clearDepartment() {
      if (this.params.childDivisionId) {
        this.setCondition("childDivisionId", null)
      }
    },
    loadDepartments() {
      const division = this.user.user.activeDivisionId
      this.$store.dispatch('auth/loadDepartments', division).then((res) => {
        this.departments = [ALL_DEPARTMENTS, ...res.data]
      })
    },
    setValues() {
      const available = this.typeDependentParams.filter(i => i.type === TYPE)
      available.forEach(item => {
        const {name, value} = item
        if (name in this.params) {
          this.params[name] = value
        }
      })
    },
    setCondition(name, value) {
      this.$store.commit('journal/SET_TYPE_DEPENDENT_PARAM', {
        type: TYPE,
        name,
        value
      })
      this.$store.dispatch('journal/fetchItems')
    }
  }
}
</script>
